








import { Component, Vue } from "vue-property-decorator";
import COSOC from "@/components/Municipalidad/COSOC.vue";

@Component({
  components: {
    COSOC
  }
})
export default class CosocView extends Vue {}










import { Component, Vue } from "vue-property-decorator";
import FormularioInscripcionGas from "@/components/Formularios/FormularioInscripcionGas.vue";

@Component({
  components: {
    FormularioInscripcionGas
  },
  meta: {
    // sets document title
    title: "Inscripción Beneficio Gas - Municipalidad de Quilpué",

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Municipalidad de Quilpué"
      },
      keywords: { name: "keywords", content: "Quilpué" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class FormularioInscripcionGasView extends Vue {
  private mounted() {
    document.title = "Inscripción Beneficio Gas - Municipalidad de Quilpué";
  }
}

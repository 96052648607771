








import { Component, Vue } from "vue-property-decorator";
import FormularioInscripcion from "@/components/Formularios/FormularioInscripcion.vue";

@Component({
  components: {
    FormularioInscripcion
  },
  meta: {
    // sets document title
    title:
      "Inscripción Seminario Día Mundial del Medio Ambiente - Municipalidad de Quilpué",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Municipalidad de Quilpué"
      },
      keywords: { name: "keywords", content: "Quilpué" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class FormularioInscripcionView extends Vue {
  private mounted() {
    document.title =
      "Inscripción Seminario Día Mundial del Medio Ambiente - Municipalidad de Quilpué";
  }
}





































































































































































































































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SelectOption {
  value: string | any;
  label: string;
}

@Component({
  components: {}
})
export default class FormularioPostulacion extends Mixins(FormValidator) {
  private win = window;
  private base = window.location;
  private formattedRut = "";
  private data: any = {
    rut: "",
    nombre: "",
    email: "",
    telefono: "",
    motivo: "",
    tipo: "",
    area: ""
  };
  private tipoSelect: SelectOption = {
    value: null,
    label: "Tipo de postulación *"
  };
  private tipoOptions: Array<SelectOption> = [
    { value: 1, label: "Trabaja con Nosotros" },
    { value: 2, label: "Práctica" }
  ];
  private areaSelect: SelectOption = {
    value: null,
    label: "Área de Trabajo *"
  };
  private areaOptions: Array<SelectOption> = [
    { value: 1, label: "Operaciones" },
    { value: 2, label: "Gestión Ambiental" },
    { value: 3, label: "Obras Municipales" },
    { value: 4, label: "Administración y Finanzas" },
    { value: 5, label: "Tránsito y transporte Público" },
    { value: 6, label: "Control" },
    { value: 7, label: "Asesoría Jurídica" },
    { value: 8, label: "Recursos Humanos" },
    { value: 9, label: "Rentas" },
    { value: 10, label: "Secpla (secretaría comunal de planificación)" },
    { value: 11, label: "Cultura" },
    { value: 12, label: "Deportes y Recreación" },
    { value: 13, label: "Comunicaciones" },
    { value: 14, label: "Seguridad Pública" },
    { value: 15, label: "Social" },
    { value: 16, label: "Desarrollo Económico y empleo" },
    { value: 17, label: "Informática" },
    { value: 18, label: "Zoologico" },
    { value: 19, label: "Otro" }
  ];
  private cv: File | null = null;
  private invalidFile = false;

  @Watch("tipoSelect")
  private tipoSelectWatch(newValue: any) {
    if (newValue && newValue.value) {
      this.data.tipo = newValue.value;
    }
  }

  @Watch("areaSelect")
  private areaSelectWatch(newValue: any) {
    if (newValue && newValue.value) {
      this.data.area = newValue.value;
    }
  }

  @Watch("formattedRut")
  private formatRutVecino(newValue: string) {
    newValue = newValue.replace(/\./g, "").toUpperCase();
    this.data.rut = newValue;
    newValue = newValue.replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  private mounted() {
    document.title = "Trabaja con Nosotros- Municipalidad de Quilpué";
  }

  private enviar() {
    if (this.data.rut.trim() == "" || !this.validateRut(this.data.rut)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Rut válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.nombre.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su nombre completo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.email.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Correo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (!this.validEmail(this.data.email)) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un correo válido.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.telefono.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese su Teléfono.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.telefono.length < 9) {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un teléfono válido (9 dígitos).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.tipo == "" || !this.data.tipo) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un Tipo de postulación.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.area == "" || !this.data.area) {
      Notify.create({
        color: "negative",
        message: "Por favor, seleccione un Área de Trabajo.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.data.motivo.trim() == "") {
      Notify.create({
        color: "negative",
        message: "Por favor, ingrese un Motivo o Interés.",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    } else if (this.cv == null) {
      Notify.create({
        color: "negative",
        message: "Por favor, adjunte un documento válido (Max 5 mb).",
        icon: "report_problem",
        position: "top",
        timeout: 2500
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    const formData = new FormData();
    if (this.cv) {
      const ciFile = this.cv as Blob;
      formData.append("cv", ciFile);
    } else {
      const ciFile = new File(["file"], "Cv") as Blob;
      formData.append("cv", ciFile);
    }
    for (const prop in this.data) {
      if (this.data.hasOwnProperty(prop)) {
        // @ts-ignore
        if (this.data[prop] || this.data[prop] === 0) {
          // @ts-ignore
          formData.append(prop, this.data[prop]);
        }
      }
    }
    this.$axios
      .post("formulario/postulacion", formData)
      .then((res: any) => {
        this.$q.loading.hide();
        Notify.create({
          color: "positive",
          message: "Se ha realizado correctamente la postulación.",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
        this.routerGo("Home");
        return;
      })
      .catch((err: any) => {
        if (err.response.data.message == "REGISTRADO") {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message:
              "El Rut ingresado ya cuenta con una postulación de ese tipo y en esa área.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        } else {
          this.$q.loading.hide();
          Notify.create({
            color: "negative",
            message: "Ha ocurrido un error, por favor intente nuevamente.",
            icon: "report_problem",
            position: "top",
            timeout: 2500
          });
          return;
        }
      });
  }
  private checkFile(target: HTMLInputElement) {
    let flag = false;
    const extensions = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
    if (target.files && target.files[0]) {
      for (const x in extensions) {
        if (target.files[0].type.includes(extensions[x])) {
          flag = true;
        }
      }
      if (target.files[0].size > 5 * 1024 * 1024 || !flag) {
        this.cv = null;
        this.invalidFile = true;
        Notify.create({
          color: "negative",
          message: "El archivo excede el máximo permitido (5mb).",
          icon: "report_problem",
          position: "top",
          timeout: 2500
        });
      } else if (target.files[0].size <= 0 || !flag) {
        this.cv = null;
        this.invalidFile = true;
      } else {
        this.cv = target.files[0];
        this.invalidFile = false;
      }
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private validEmail(email: string) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }
  public validateRut(newValue: string) {
    let texto = newValue;
    let tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    const largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    let invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    let dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    let cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    const dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    const largo = crut.length;
    if (largo < 2) {
      return false;
    }
    let rut = "";
    if (largo > 2) rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    const dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    let dvr = "0";
    let suma = 0;
    let mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    const res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      const dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }
}
